import React from "react";
import { FaEnvelope, FaLinkedin, FaGithub } from "react-icons/fa";
import '../index.css';

function Intro() {
  return (
    <div className="Intro min-h-screen flex items-center justify-center bg-white px-4">
      <div className="max-w-2xl text-left">
        <h1 className="text-2xl font-lg mb-4">
          andrew bempong <span role="img" aria-label="laptop emoji">👨🏿‍💻</span>
        </h1>
        <p id="about" className="text-4xl font-lg mb-3 mt-8">
          <span className="moving-gradient h">h</span>
          <span className="moving-gradient e">e</span>
          <span className="moving-gradient y">y</span>
          <span className="moving-gradient exclamation">!</span>
        </p>
        
        <p className="text-lg mb-6">
        I’m a Computer Science student @ Stanford University completing both my Master's and Bachelor's simultaneously. Specializing in AI ethics, I’ve been enamored with the intersection between the humanities and technology ever since I can remember—I aspire to fuse the two in my career to critically analyze the effects, biases, and consequences of emergent technology on the public. Particularly interested in leveraging technical expertise to critically scrutinize AI innovations in media & policy, as well as broadening global access to cutting-edge technology to the underserved. 
        </p>
        <p className="text-lg mb-6">Currently in pursuit of becoming <a href="https://artful.design/stuff/samples/pi.pdf" className="text-blue-500 hover:underline">pi-shaped</a>.</p>
        <p className="text-lg mb-6">
          If I’m not building, I’m probably playing the guitar, running, or traveling.
        </p>
        <p className="text-lg mb-6 flex items-center">
          Don’t be a stranger!
          <a href="mailto:bempong@stanford.edu" className="flex items-center hover:underline ml-2">
            <FaEnvelope className="mr-1" />
          </a>
          <a href="https://www.linkedin.com/in/andrew-bempong" className="flex items-center hover:underline mx-2">
            <FaLinkedin className="mr-1" /> 
          </a>
          <a href="https://github.com/bempong" className="flex items-center hover:underline">
            <FaGithub className="mr-1" />
          </a>
        </p>
      </div>
    </div>
  );
}

export default Intro;
